#footerBox {
  width: 100%;
  height: 221px;
  background: #C59C70;
  overflow: hidden;
  margin: 0 auto;
  .container {
    width: 1440px;
    font-family: PingFangSC-Regular;
    font-size: 18px;
    color: #FFFFFF;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    .column1 {
      padding-top: 34px;
      .title {
        font-size: 28px;
      }
      .address {
        margin-top: 11px;
        margin-bottom: 42px;
      }
    }
    .column2, .column3, .column4 {
      padding-top: 44px;
      .title {
        font-family: PingFangSC-Medium;
        font-size: 20px;
        font-weight: 500;
      }
      .erweima {
        margin-top: 8px;
        width: 98px;
        // height: 98px;
      }
    }
    .column2, .column3 {
      .number {
        margin-top: 14px;
        margin-bottom: 8px;
      }
    }
  }
}


// 125%分辨率(innerWidth-1516)： 在小于或等于1600 像素的屏幕的情况下
@media screen and (max-width: 1600px) {
  #footerBox {
      zoom:80%;
  }
}

// 150%分辨率(innerWidth-1260)： 在小于或等于 1300 像素的屏幕的情况下
@media screen and (max-width: 1300px) {
  #footerBox {
      zoom:66%;
  }
}