.fixed-body{
    position: fixed;
    left:0;
    top:0;
    z-index: 999;
    .topBox {
        background-color:rgba(255,255,255,.9)!important;
        box-shadow: 5px 5px 10px rgba(0,0,0,0.1);
    }
}

.topBox {
    height: 103px;
    width: 1920px;
    margin: 0 auto;
    position: relative;
    background-color: #fff;

    .topLogo {
        position: absolute;
        left: 241px;
        top: 0;
        z-index: 1;
    }

    .box {
        position: relative;
        width: 1200px;
        margin: 0 auto;
        margin-left: 610px;
        display: flex;
        justify-content: center;
    }

    .nav {
        height: 103px;
        z-index: 10;
        position: relative;
        margin: 0 auto;

        li {
            display: inline-block;
            width: 100px;
            height: 103px;
            position: relative;
            font-family: PingFangSC-Regular;
            font-size: 24px;
            color: #3E3A39;
            font-weight: 400;
            margin-right: 50px;
            padding: 0px;
        }

        a {
            display: block;
            width: 100px;
            height: 103px;
            line-height: 103px;
            text-align: center;
            cursor: pointer;
            font-family: PingFangSC-Regular;
            font-size: 24px;
            color: #3E3A39;
            font-weight: 400;
        }

        a.cur {
            color: #C59C70;
            &::after {
                content: '';
                display: block;
                width: 52px;
                height: 4px;
                background-color: #C59C70;
                position: absolute;
                top: 70px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
}


// 125%分辨率(innerWidth-1516)： 在小于或等于1600 像素的屏幕的情况下
@media screen and (max-width: 1600px) {
    .topBox {
        zoom:80%;
    }
}
  
// 150%分辨率(innerWidth-1260)： 在小于或等于 1300 像素的屏幕的情况下
@media screen and (max-width: 1300px) {
    .topBox {
        zoom:66%;
    }
}