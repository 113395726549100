html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
input {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  font-family: 'Microsoft YaHei', 'simsun', 'Myriad Set Pro', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
  color: #333333;
  min-width: 1200px;
  overflow-x: hidden;
}
ol,
ul {
  list-style: none;
}
img,
input,
a {
  border: 0;
  vertical-align: top;
}
input {
  outline: none;
}
a {
  color: #333;
  text-decoration: none;
}
a:hover {
  color: #403b36;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
input::-webkit-input-placeholder {
  color: #cdcdcd;
}
input::-moz-placeholder {
  color: #cdcdcd;
}
input:-ms-input-placeholder {
  color: #cdcdcd;
}
.clearfl {
  zoom: 1;
}
.clearfl:after {
  clear: both;
  content: '';
  display: block;
  width: 0;
  height: 0;
  visibility: hidden;
}
.outerlayer {
  display: block;
  overflow: hidden;
  margin: 0 auto;
  background-color: #fff;
}
.container {
  width: 1200px;
  margin: 0 auto;
  display: block;
  overflow: hidden;
}
.margin0 {
  margin: 0;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
@font-face {
  font-family: 'sfont';
  src: url('font/sfont.eot');
  src: url('font/sfont.eot?#iefix') format('embedded-opentype'), url('font/sfont.woff') format('woff'), url('font/sfont.ttf') format('truetype'), url('font/sfont.svg#sfont') format('svg');
}
