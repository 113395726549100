.floatWindow-box {
    width: 343px!important;
    /* height:503px!important; */
    height:auto!important;
    position: fixed;
    top: 300px;
    left: 20px;
    margin-top: 0;
    margin-left: 0; /* 图片宽度的一半 */
    z-index: 100000;
    background-image: url('../../images/floatBack.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding:10px;
    padding-bottom:30px;
  }
  
 .rangeList {
    height: auto;
    width:322.31px;
    margin:0 auto;
    margin-bottom: 10px;
    img {
        width: 322.31px;
        height: 142px;
        cursor: pointer;
        margin-bottom: 10px;
    }
  }

  .close {
    text-decoration:none;
    font-size: 16px;
    color: #C59C70;
    letter-spacing: -0.44px;
    font-weight: 700;
    position: absolute;
    left: 50%;
    margin-left: -45px;
    bottom: 10px;
    cursor:pointer;
    display: flex;
    align-items: center;
    img {
        margin-right: 7px;
        vertical-align: bottom;
        width: 21px;
        height: 21px;
      }
  }
  

// 飘窗页面位置:右侧+上下浮动
.right01{
    left: auto!important;
    right: 20px!important;
}

// 点击关闭时隐藏飘窗
.hideDom{
    visibility: hidden!important;
}


// 125%分辨率(innerWidth-1516)： 在小于或等于1600 像素的屏幕的情况下
@media screen and (max-width: 1600px) {
  .floatWindow-box {
      zoom:80%;
  }
}

// 150%分辨率(innerWidth-1260)： 在小于或等于 1300 像素的屏幕的情况下
@media screen and (max-width: 1300px) {
  .floatWindow-box {
      zoom:66%;
  }
}