@import 'page/common.less';

@font-face {
  font-family: 'sfont';
  src: url('font/sfont.eot');
  src: url('font/sfont.eot?#iefix') format('embedded-opentype'),
    url('font/sfont.woff') format('woff'),
    url('font/sfont.ttf') format('truetype'),
    url('font/sfont.svg#sfont') format('svg');
}
