.floatWindow-box {
  width: 343px!important;
  /* height:503px!important; */
  height: auto!important;
  position: fixed;
  top: 300px;
  left: 20px;
  margin-top: 0;
  margin-left: 0;
  /* 图片宽度的一半 */
  z-index: 100000;
  background-image: url('../../images/floatBack.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
  padding: 10px;
  padding-bottom: 30px;
}
.rangeList {
  height: auto;
  width: 322.31px;
  margin: 0 auto;
  margin-bottom: 10px;
}
.rangeList img {
  width: 322.31px;
  height: 142px;
  cursor: pointer;
  margin-bottom: 10px;
}
.close {
  text-decoration: none;
  font-size: 16px;
  color: #C59C70;
  letter-spacing: -0.44px;
  font-weight: 700;
  position: absolute;
  left: 50%;
  margin-left: -45px;
  bottom: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.close img {
  margin-right: 7px;
  vertical-align: bottom;
  width: 21px;
  height: 21px;
}
.right01 {
  left: auto!important;
  right: 20px!important;
}
.hideDom {
  visibility: hidden!important;
}
@media screen and (max-width: 1600px) {
  .floatWindow-box {
    zoom: 80%;
  }
}
@media screen and (max-width: 1300px) {
  .floatWindow-box {
    zoom: 66%;
  }
}
